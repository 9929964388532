.modal-title {
    margin: 30px auto 9px;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: 0.15px;
    color: #BD0A00;
}
.modal-text{
    max-width: 520px;
    color: #263238;
    margin: 0 auto 24px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.44px;
}
.modal-link {
    text-decoration: underline;
    color: #263238;
    font-weight: bold;
}

.modal-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.modal-button {
    font-size: 14px;
    font-weight: bold;
    padding: 12px;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 12px;
}

button.modal-button {
    border: none;
    margin-right: 12px;
 }

a.modal-button {
   border-radius: 4px;
}

.modal-button-bk {
    color: #D62300;
}

a.modal-button-bk {
    border: 1px solid #D62300;
}